import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`30:00 AMRAP of:`}</p>
    <p>{`40 Double Unders`}</p>
    <p>{`30 Wall Balls (20/14)`}</p>
    <p>{`200M Run`}</p>
    <p>{`10/Arm KB Power Clean (53/35)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is the Marathon & Mini Marathon so there may be roads blocked
on your trip to the Ville.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      